<template>
  <div class="qhse">
    <head-nav></head-nav>
    <div class="qhse-banner">
      <img
        src="@/assets/image/qhse/qhse-banner.png"
        alt=""
      />
    </div>
    <div class="qhse-box1">
      <div class="wrap">
        <div class="qhse-top">
          <h3 class="qhse-top-title">
            <img
              :src="$t('qhse.title-1')"
              alt=""
            />
          </h3>
          <div class="qhse-top-img">
            <div
              v-for="item in $t('qhse.qhse1')"
              :key="item.img"
              class="qhse-top-imgs"
            >
              <img :src="item.img" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qhse-box2">、
      <div class="wrap">
        <h3 class="qhse-title">
          <img
            :src="$t('qhse.titleimg')"
            alt=""
          />
        </h3>

        <div class="qhse-content">
          <div
            class="qhse-list"
            v-for="(item, index) in $t('qhse.target')"
            :key="index"
          >
            <div class="qhse-list-num">
              {{ (index + 1).toString().padStart(2, '0') }}
            </div>
            <div class="qhse-list-content">
              <div class="info">
                <p :style="$i18n.locale ==='en'?'font-size:20px;':''">{{item.title}}</p>
                <h3 :style="$i18n.locale ==='en'?'font-size:24px;':''">{{item.content}}</h3>
              </div>
              <img
                class="img"
                :src="item.img"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="qhse-box3">
      <div class="wrap">
        <h3 class="qhse-title">
          <img
            :src="$t('qhse.title-2')"
            alt=""
          />
        </h3>

        <div
          class="qhse-img"
          :class="$i18n.locale ==='en'?'qhse-img-active':''"
        >
          <div
            class="qhse-imgs"
            v-for="item in $t('qhse.qhse2')"
            :key="item.id"
            :style="item.id === 3 && $i18n.locale ==='cn'?'padding: 30px  0px;':''"
          >
            <div
              v-for="line in item.title"
              :key="line"
            >
              {{ line }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import HeadNav from './head-nav';

import Footers from './footers';

export default {
  title: 'Change direction',
  components: {
    HeadNav,
    Footers,
  },
  apollo: {
    qhse2: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              qhse2: allCommitments {
                title
              }
            }
          `;
        }
        return gql`
          query {
            qhse2: allEnCommitments {
              title
            }
          }
        `;
      },
    },
  },
  data() {
    return {
      qhse2: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.qhse {
  .qhse-banner {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: auto;
    }
  }

  .qhse-box1 {
    .qhse-top {
      padding: 90px 0 0 0;

      .qhse-top-title {
        text-align: center;
        margin-bottom: 30px;
      }

      .qhse-top-img {
        margin-top: 40px;
        height: 400px;
        position: relative;

        .qhse-top-imgs {
          img {
            width: 100%;
            height: 100%;
          }
        }

        .qhse-top-imgs:nth-child(1) {
          width: 480px;
          height: 150px;
          position: absolute;
          top: 0;
          left: 0;
        }

        .qhse-top-imgs:nth-child(2) {
          width: 240px;
          height: 150px;
          position: absolute;
          top: 0;
          left: 480px;
        }

        .qhse-top-imgs:nth-child(3) {
          width: 240px;
          height: 150px;
          position: absolute;
          top: 0;
          left: 720px;
        }

        .qhse-top-imgs:nth-child(4) {
          width: 240px;
          height: 150px;
          position: absolute;
          top: 0px;
          left: 960px;
        }

        .qhse-top-imgs:nth-child(5) {
          width: 240px;
          height: 150px;
          position: absolute;
          top: 150px;
          left: 0px;
        }

        .qhse-top-imgs:nth-child(6) {
          width: 480px;
          height: 150px;
          position: absolute;
          top: 150px;
          left: 240px;
        }

        .qhse-top-imgs:nth-child(7) {
          position: absolute;
          left: 720px;
          top: 150px;
          width: 240px;
          height: 150px;
        }

        .qhse-top-imgs:nth-child(8) {
          position: absolute;
          left: 960px;
          top: 150px;
          width: 240px;
          height: 150px;
        }
      }
    }
  }

  .qhse-box2 {
    padding: 90px 0 100px 0;
    background: #eee;
    .qhse-title {
      text-align: center;
      margin-bottom: 30px;
    }

    .qhse-content {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;

      .qhse-list {
        width: 380px;

        .qhse-list-num {
          height: 60px;
          line-height: 60px;
          padding-left: 36px;
          background: #ffbf00;
          font-size: 36px;
          position: relative;

          &::after {
            content: '';
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #ffbf00;
            position: absolute;
            bottom: -10px;
            left: 40px;
          }
        }

        .qhse-list-content {
          height: 140px;
          background: #fff;
          padding: 30px;
          display: flex;
          justify-content: space-between;

          .info {
            p {
              font-size: 20px;
            }
            h3 {
              margin-top: 10px;
              font-size: 34px;
            }
          }
          .img {
            height: 130px;
          }
        }
      }
    }
  }

  .qhse-box3 {
    width: 100%;
    height: 770px;

    .wrap {
      padding: 90px 0 30px 0;

      .qhse-title {
        text-align: center;
      }

      .qhse-img {
        margin-top: 40px;
        position: relative;

        .qhse-imgs {
          position: absolute;
          background: #666666;
          width: 300px;
          height: 150px;
          margin: 10px auto;
          color: #fafafa;
          line-height: 24px;

          div {
            margin: 40px 30px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .qhse-imgs:nth-child(1) {
          top: 0;
          left: 0;
          width: 300px;
          height: 300px;
          background: #ffbf00;
          color: #000;

          div {
            margin: 120px 60px;
            font-size: 18px;
            text-align: center;
          }
        }

        .qhse-imgs:nth-child(2) {
          position: absolute;
          top: 0;
          left: 300px;
        }
        .qhse-imgs:nth-child(3) {
          position: absolute;
          top: 0;
          left: 600px;
          line-height: 20px;
          div:nth-child(1) {
            margin: 10px 10px 0 10px;
          }
          div {
            margin: 0 10px;
            font-size: 0.85rem;
          }
        }
        .qhse-imgs:nth-child(4) {
          position: absolute;
          top: 0;
          left: 900px;
          text-align: center;

          div:nth-child(1) {
            margin: 30px 30px 0 30px;
          }
          div {
            margin: 0 30px;
          }
        }
        .qhse-imgs:nth-child(5) {
          position: absolute;
          top: 150px;
          left: 300px;
        }
        .qhse-imgs:nth-child(6) {
          position: absolute;
          top: 150px;
          left: 600px;
        }
        .qhse-imgs:nth-child(7) {
          position: absolute;
          top: 150px;
          left: 900px;
        }

        .qhse-imgs:nth-child(2n + 2) {
          background: #eeeeee;
          color: #666;
        }
      }

      .qhse-img-active {
        .qhse-imgs:nth-child(4) {
          div:first-child {
            margin: 10px 10px 0 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .qhse-banner {
    width: 1200px;
  }
  .qhse .qhse-banner img {
    width: 1200px;
  }

  .qhse .qhse-box1,
  .qhse .qhse-box2 {
    width: 1200px;
  }
  .qhse .qhse-box2 .wrap .qhse-img .qhse-imgs {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
